import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFreq } from "../FreqContext";

import waveDrawLoop from './lib/waveDrawLoop.js';
import WaveCanvas from './WaveCanvas.jsx';
import RefTable from '../table/RefTable';
import CaliTable from '../table/CaliTable';
import './Wave.css';

import { Slider, Button, Col, Row, Flex, Dropdown, Space, Modal, Collapse, message, Tooltip, Tag } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css'; 

const items = [
    {
        key: '0',
        label: 'Baseline',
    },
    {
      key: '1',
      label: 'Moderately Darker (Baseline * 0.92)',
    },
    {
      key: '2',
      label: 'Slightly Darker (Baseline * 0.98)',
    },
    {
      key: '3',
      label: 'Slightly Brighter (Baseline * 1.02)',
    },
    {
        key: '4',
        label: 'Moderately Brighter (Baseline * 1.08)',
      },
];

const tooltiptext=<span>Adjust the slider to the desired value and click 'Save as custom value' to set a custom value for the current vowel. Once saved, the custom value will be used as the default target value.</span>

const Wave = ({currentVowelIdx, refFreqArray}) => {
    const { freqData, updateFreqData } = useFreq();
    const navigate = useNavigate();
    
    const currentVowel = refFreqArray?.[currentVowelIdx]?.vowel || '';
    const refFreq = refFreqArray?.[currentVowelIdx]?.freq || 0;
    const defaultFreq = freqData?.customFrequencies?.[currentVowel] || freqData?.caliBaseline?.[currentVowel] || refFreq || 1500;

    const [waveIsPaused, setWaveIsPaused] = useState(false);
    const [pos, setPos] = useState( defaultFreq );
    const [messageApi, contextHolder] = message.useMessage();

    // For Modals
    const [refOpen, setRefOpen] = useState(false);
    const [caliOpen, setCaliOpen] = useState(false);

    useEffect(() => {
        if (!refFreqArray || refFreqArray.length === 0 || currentVowelIdx >= refFreqArray.length) {
          // message.error('No vowel data provided. Please select vowels first. Redirecting to home page...');
          setTimeout(() => {
            navigate('/resonance/selection');
          }, 2000);
        }
      }, [refFreqArray, currentVowelIdx, navigate]);

    const text =
        <>
            We provide 3 types of values: Referenced, Calibrated, and Customized. You can jump to any of these target by clicking the buttons below. If the button is disabled,
            it means you haven't set the corresponding value yet. To set a customized target, you can drag the slider below the waveform to the desired position, and click the button "Set Current Value as Customized Target".
            To set a calibrated target, please start from the <Link to="/resonance/calibrate">calibration routine</Link>. Just record your voice and follow the instructions, then the calibrated values will be automatically calculated.
            You can also batch set calibrated and customized values manually <a onClick={()=>setCaliOpen(true)}>here</a>.
        </>

    useEffect(() => {
        if (defaultFreq) {
            setPos(defaultFreq);
            console.log(`Default Frequency is set to ${defaultFreq} Hz. currentVowel is ${currentVowel}.`)
        }
    }, [defaultFreq, currentVowel]);

    useEffect(() => {
        if (freqData.customFrequencies[currentVowel]) {
            setPos(freqData.customFrequencies[currentVowel]);
        }
    }, [freqData.customFrequencies, currentVowel]);

    const handlePauseWave = (e) => setWaveIsPaused(!waveIsPaused)

    const handleJumpToRef = () => setPos(refFreq || 1500);

    const handleJumpToCustom = () => {
        setPos(freqData.customFrequencies[currentVowel]);
    };

    const handleSetCurrentAsCustom = () => {
        const updates = {
            customFrequencies: { [currentVowel]: pos }
        };
        updateFreqData(updates);
        console.log(`Freq Data: ${freqData.customFrequencies[currentVowel]}`)
        success(pos);
    };
    
    const handleOnChangeSlider = (value) => {
        setPos(value)
    };

    const success = (pos) => {
        messageApi.open({
          type: 'success',
          content: 'Successfully set the customized target frequency to ' + pos + ' Hz.',
        });
    };

    const handleJumpToCalibratedTarget = (e) => {
        const caliBaseline = freqData.caliBaseline[currentVowel];
        if (caliBaseline !== undefined && caliBaseline !== '') {
            switch (e.key) {
                case '0':
                    setPos(caliBaseline);
                    break;
                case '1':
                    setPos(Math.round(caliBaseline * 0.92));
                    break;
                case '2':
                    setPos(Math.round(caliBaseline * 0.98));
                    break;
                case '3':
                    setPos(Math.round(caliBaseline * 1.02));
                    break;
                case '4':
                    setPos(Math.round(caliBaseline * 1.08));
                    break;
                default:
                    setPos(Math.round(caliBaseline));
            }
        }
        console.log('Current Vowel:', currentVowel);
        console.log('Custom Frequencies:', freqData.customFrequencies);
        console.log('Calibrated Baseline:', freqData.caliBaseline);
    }

    return (
        <>
            {contextHolder}
            {/* <div style={{ width: '70%' }}>
                <Collapse
                    items={[{ key: '1', label: 'Instructions', children: <div>{text}</div> }]}
                />
            </div> */}

            <Flex gap="small" style={{margin:'10px'}}>
                <Tooltip
                  placement="top"
                  title={refFreq === pos ? <span>This button is disabled because the current value is already the reference value.</span> : null}
                
                >
                  <Button 
                      onClick={handleJumpToRef}
                      disabled={refFreq === pos}
                  >
                      Jump to Reference Value ({refFreq} Hz)
                  </Button>
                </Tooltip>

                {/* <Dropdown
                    menu={{
                        items,
                        onClick: handleJumpToCalibratedTarget
                    }}
                    disabled={freqData.caliBaseline[currentVowel] === undefined || freqData.caliBaseline[currentVowel] === ''}
                >
                    <Button>
                        <Space>
                            Jump to Calibrated Value
                            <DownOutlined />
                        </Space>
                    </Button>
                    
                </Dropdown> */}
                <Tooltip 
                  placement="top"
                  title={
                    freqData.customFrequencies[currentVowel] === '' || freqData.customFrequencies[currentVowel] === undefined || freqData.customFrequencies[currentVowel] === pos 
                    ? <span>This button is disabled because no custom value is set or the current value is already the custom value.</span> 
                    : null
                  }
                >
                  <Button 
                      onClick={handleJumpToCustom}
                      disabled={freqData.customFrequencies[currentVowel] === '' || freqData.customFrequencies[currentVowel] === undefined || freqData.customFrequencies[currentVowel] === pos}
                  >
                      Jump to Custom Value {freqData.customFrequencies[currentVowel] ? `(${freqData.customFrequencies[currentVowel]} Hz)` : ''}
                  </Button>
                </Tooltip>
                {/* <Tooltip title={tooltiptext}
                >
                  <QuestionCircleOutlined style={{ marginLeft: 1}} />
                </Tooltip> */}
                <Row>
                    <Col span={24}> 
                        <Tag color='purple'>Current: {pos} Hz</Tag>
                        <Tooltip placement="right" title={tooltiptext}>
                          <Button
                              type="primary"
                              onClick={ handleSetCurrentAsCustom }
                              style = {{ marginLeft: '10px' }}
                          >
                              Save as custom value
                          </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Flex>
            <div className="wlMainParent">
                <Row>
                    <Col span={24}>
                        <div className="canvasandslider">
                            <WaveCanvas
                                className="waveCanvas"
                                draw={waveDrawLoop}
                                isPaused={waveIsPaused}
                                strokeStyle="#C8A1E0"
                            />
                            <Slider
                                className="triangle-slider"
                                min={0}
                                max={4096}
                                value={pos}
                                onChange={handleOnChangeSlider}
                                defaultValue={1500}
                                style={{ margin: 0}}
                                tooltip={{open: false}}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                              <span>Darker</span>
                              <span>Brighter</span>
                            </div>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                              <svg width="750" height="20" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                  <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
                                    <stop offset="0%" style={{ stopColor: '#2D0054'}} />
                                    <stop offset="100%" style={{ stopColor: '#9501F6'}} />
                                  </linearGradient>
                                </defs>
                                <line x1="10" y1="10" x2="740" y2="10" stroke="url(#grad1)" strokeWidth="5" />
                                <polygon points="10,5 0,10 10,15" fill="2D0054" />
                                <polygon points="740,5 750,10 740,15" fill="#9501F6" />
                              </svg>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <span className="label">Waveform Play Control: </span>
                        <Button
                            onClick={ handlePauseWave }
                            style={{ marginTop: '10px', marginLeft: '10px'}}
                            type="primary"
                        >
                            {(waveIsPaused) ? "Play" : "Pause"}
                        </Button>
                    </Col>
                </Row>
            </div>
            <Modal
                title="Reference Frequency Values"
                centered
                open={refOpen}
                onOk={() => setRefOpen(false)}
                onCancel={() => setRefOpen(false)}
                width={1000}
                footer={[
                <Button key="submit" type="primary" onClick={() => setRefOpen(false)}>
                    OK
                </Button>
                ]}
            >
                <RefTable />
            </Modal>
            <Modal
                title="Calibrated Frequency Values"
                centered
                open={caliOpen}
                onOk={() => setCaliOpen(false)}
                onCancel={() => setCaliOpen(false)}
                width={1000}
                footer={[
                <Button key="submit" type="primary" onClick={() => setCaliOpen(false)}>
                    OK
                </Button>
                ]}
            >
                <CaliTable />
            </Modal>
        </>
    )
}

export default Wave;